<template>
    <v-row id="content-details" class="ma-0 pa-0">
        <v-col cols="6" class="col-lg-8 py-0 pl-0">
            <MessageDetails />
        </v-col>
        <v-col cols="6" class="col-lg-4 py-0 pr-0 pl-0">
            <DonationDetails />
        </v-col> 
    </v-row>
</template>
<script>
export default {
  components: {
    MessageDetails: () => import("@/pages/active-campaign/on-air/components/content-details-components/MessageDetails"),
    DonationDetails: () => import("@/pages/active-campaign/on-air/components/content-details-components/DonationLists"),
  },
  data() {
    return {
    }
  }
}
</script>